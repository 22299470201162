<template>
  <v-list-item>
    <v-list-item-avatar size="32">
      <v-avatar color="grey lighten-3">{{ position }}</v-avatar>
    </v-list-item-avatar>

    <v-list-item-content>
      <div class="d-flex">
        <form-field-text
          :disabled="disabled"
          :value="fPercent"
          :wait="fWait"
          input-type="number"
          label="discount.percent"
          rules="min_value:0|max_value:100"
          @save="onApplyDiscount($event, false)"
          @input:debounce="onSetPercent"
        />

        <form-field-text
          :rules="valueRules"
          :value="sValue"
          :wait="fWait"
          class="ml-4"
          disabled
          input-type="number"
          label="discount.value"
        />
        <!--          @save="onApplyDiscount($event, true)"-->
        <!--          @input:debounce="onSetValue"-->
      </div>
    </v-list-item-content>

    <v-list-item-action>
      <v-btn
        :disabled="disabled || !fItemValue"
        color="red"
        icon
        small
        text
        @click="onRemove"
      >
        <icon-trash size="1.2rem" />
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import IconTrash from "@/components/icons/IconTrash.vue";
import { Discount, DiscountData } from "@planetadeleste/vue-mc-gw";
import { getPercent, percent, round } from "@/plugins/helpers";
import { delay } from "lodash";
import useMath from "@/composables/math";
import { InvoiceModule } from "@/store/invoice";

export interface DiscountDataExt extends DiscountData {
  limit: number;
}

const { math } = useMath();

@Component({
  components: { IconTrash },
})
export default class InvoiceItemDiscount extends Vue {
  @Prop(Object) readonly item!: Discount;

  /**
   * Set disabled, read only component
   * @type {Boolean}
   */
  @Prop(Boolean) readonly disabled!: boolean;

  /**
   * Position number on discount collection
   */
  @Prop(Number) readonly position!: number;

  /**
   * Tax value to display on fixed value field
   */
  @Prop([Number, String]) readonly taxPercent!: number;

  /**
   * Used to display price with/without tax
   */
  @Prop(Boolean) readonly priceWithTax!: boolean;

  cancelDebounce = false;
  fWait = 1500;

  get inView() {
    return InvoiceModule.inView;
  }

  get limit(): number {
    return this.item ? this.item.get("limit", 0) : 0;
  }

  get price(): number {
    if (!this.limit) {
      return 0;
    }

    return this.priceWithTax && this.taxPercent
      ? round(math.add(this.limit, percent(this.limit, this.taxPercent)))
      : this.limit;
  }

  get fixed(): boolean {
    return this.item.get("fixed", false);
  }

  get valueRules() {
    return {
      min_value: 0,
      max_value: this.price,
    };
  }

  get fPercent(): number {
    if (!this.fixed) {
      return this.fItemValue || 0;
    }

    return this.price && this.fItemValue
      ? getPercent(this.fItemValue, this.price, true)
      : 0;
  }

  get fValue(): number {
    if (this.inView) {
      return this.item.get("display_value", 0);
    }

    if (this.fixed) {
      return this.fItemValue || 0;
    }

    return this.price && this.fItemValue
      ? percent(this.fItemValue, this.price, true, 6)
      : 0;
  }

  get sValue() {
    return round(this.fValue, 3);
  }

  get fItemValue() {
    return this.item.get("value", 0);

    // if (!this.priceWithTax || !this.fixed) {
    //   return fValue;
    // }
    //
    // return math.add(fValue, percent(fValue, this.taxPercent, true));
  }

  onRemove() {
    /**
     * Remove inline discount
     */
    this.$emit("remove", this.item);
  }

  onApplyDiscount(fValue: number, bFixed: boolean) {
    if (bFixed === true) {
      this.onSetValue(fValue);
    } else {
      this.onSetPercent(fValue);
    }
  }

  onSetDiscountData(fValue: number, bFixed: boolean) {
    this.item.set("fixed", bFixed);
    this.item.set("value", fValue);
    this.cancelDebounce = true;

    delay(() => {
      this.cancelDebounce = false;
    }, this.fWait + 10);

    delay(() => {
      /**
       * Apply discount
       * @param {DiscountData}
       */
      this.$emit("apply-discount", this.item);
    }, 500);
  }

  onSetValue(fValue: number) {
    if (this.cancelDebounce) {
      return;
    }

    this.onSetDiscountData(fValue, true);
  }

  onSetPercent(fValue: number) {
    if (this.cancelDebounce) {
      return;
    }

    this.onSetDiscountData(fValue, false);
  }
}
</script>
